import React, { FC, useEffect, memo } from 'react'
import { TailSpin } from 'react-loader-spinner'

import Layout from '@components/layout'

import { customNavigate } from '@utils/utils'
import { headers } from '@utils/constants'

const Main: FC = () => {
  useEffect(() => void customNavigate('/discounts'), [])
  return (
    <Layout center>
      <TailSpin color="#00AD64" height={30} width={30} />
    </Layout>
  )
}

export async function getServerData() {
  return { headers }
}

export default memo(Main)
